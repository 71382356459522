import * as React from 'react';

export const Footer: React.FC = () => {
  return (
    <header className="bg-white py-8">
      <div className="bg-gray-800 py-8 text-white">
        <div className="container mx-auto">
          <div className="md:grid md:grid-cols-2 md:gap-4">
            <div>
              <h3>Connect</h3>
              <ul className="m-0 p-0 list-none text-white">
                <li>Instagram</li>
              </ul>
            </div>
            <div>
              <h3>Organisatie</h3>
              <ul className="m-0 p-0 list-none text-white">
                <li>Over Haagsche Schil</li>
                <li>Veelgestelde vragen</li>
                <li>Contact</li>
                <li>Vacatures</li>
                <li>Dashboard</li>
                <li>Partners</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm text-gray-400 text-center py-4">
        © 2017-2018 Haagsche Schil - door urbanlink
      </p>
    </header>
  );
};
